import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { LoadingOutlined, SafetyOutlined, EyeOutlined, EyeInvisibleOutlined, MailOutlined } from '@ant-design/icons';

import logoQues from '../assets/images/QUESTRAVEL-LOGO.svg';

const Login = ({ login, userLoggedIn }) => {

    const [errorApi, setErrorApi] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ showPass, setShowPass ] = useState(false);

	const [usernameYPassword, setUsernameYPassword] = useState({
		email: "",
		password: "",
	});


	function handleInputChange(e) {
		setErrorApi([]);
		setUsernameYPassword({
			...usernameYPassword,
			[e.target.name]: e.target.value
		});
	}


	async function handleSubmit(e) {
		e.preventDefault();

		try {
            setLoading(true);
			await login(usernameYPassword.email, usernameYPassword.password);
            setLoading(false);

		} catch (error) {

            console.log( error );
            if( error.response){
                setErrorApi(error.response.data);
            }

            setLoading(false);

		}

	}


    if( userLoggedIn ){
        
        return <Redirect to={
            {
              pathname: '/',
            }
        } />
        
    }


    return ( 
        <section className="wrapLogin">
            <div className="container">

                <header>
                    <img src={logoQues} alt="Ques.travel" className="mainLogoQues img-fluid" />
                </header>

                <form onSubmit={handleSubmit}>
                    <h2>Iniciar sesión</h2>
                    <div className="form-floating">
                        <MailOutlined />
                        <input type="email" name="email" required="required" placeholder="Escriba su email" className="form-control"
                            onChange={handleInputChange}
                            value={usernameYPassword.email}
                        />
                        <label htmlFor="email">Escriba su email</label>
                    </div>
                    <div className="form-floating">
                        {showPass ? <EyeOutlined onClick={()=>setShowPass(false)} /> : <EyeInvisibleOutlined onClick={()=>setShowPass(true)} /> }
                        <input type={showPass ? 'text' : 'password' } name="password" required="required" placeholder="Escriba su password" className="form-control"
                            onChange={handleInputChange} 
                        />
                        <label htmlFor="password">Contraseña</label>
                    </div>

                    <button type="submit" className="btn btnRegistrarse" disabled={loading ? true : ''}>
                        { loading ? <LoadingOutlined /> : <SafetyOutlined /> } Ingresar
                    </button>


                    {Object.keys(errorApi).length > 0 ? 
                        <div className="alert alert-warning text-center">
                            Usuario o contraseña inválida.
                        </div>
                    :null}
                    
                    <div className="row">
                        <div className="col-lg-6">
                            <Link to="./forgot">Recuperar mi contraseña</Link>
                        </div>
                    </div>
                    
                </form>
                
                
            </div>
        </section>
    );
}
 
export default Login;