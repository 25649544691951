import React, {useState } from 'react';
import Axios from 'axios';
// import Select from 'react-select';
import  { SafetyOutlined, LoadingOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const EditUser = ({ userLoggedIn, editing, setRefresh }) => {
    
    const [ registeringUser, setRegisteringUser ] = useState(false);
    const [ validationError, setValidationError ] = useState({});
    const [ success, setSuccess] = useState('');
    const [ showPass, setShowPass ] = useState(false);

    const [userData, setUserData] = useState({
		fname: editing.fname,
        lname: editing.lname,
		password: ""
	});

    function handleInputChange(e) {
		setUserData({
			...userData,
			[e.target.name]: e.target.value
		});

        setValidationError({});
	}


    async function handleSubmit(e) {
		e.preventDefault();

        try {

            setRegisteringUser(true);

            const { data } = await Axios.patch(`${process.env.REACT_APP_API_URL}/api/users/${editing.id}`, userData);

            if( data.code === 422 ){
                
                setRegisteringUser(false);
                setValidationError(data.message);

            }else if( data.code === 200){
                
                setSuccess('¡El usuario ha sido actulizado satisfactoriamente!');
                setRefresh(Math.random());
            }
            
            setRegisteringUser(false);

        } catch (error) {
            console.log(error);
            setRegisteringUser(false);
        }
	}


    return ( 
        <>
        <form onSubmit={handleSubmit}>

            { validationError.length > 0 ? 
                <div className="alert alert-warning mb-2 rounded-0">
                    <ul>
                        {validationError.map( (item, i) => (
                            <li key={i}>
                                { item.fname ? item.fname : null }
                                { item.lname ? item.lname : null }
                                { item.password ? item.password : null }
                            </li>
                        ))}
                    </ul>
                </div>
            :null }

            <div className="row">
                <div className="col-6">
                    <div className="form-floating">
                        <input type="text" name="fname" placeholder="Nombre" className="form-control"
                            defaultValue={editing.fname}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="fname">Nombre</label>
                    </div>
                    
                </div>
                <div className="col-6">
                    <div className="form-floating">
                        <input type="text" name="lname" placeholder="Apellidos" className="form-control"
                            defaultValue={editing.lname}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="lname">Apellidos</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating">
                        <input type="email" name="email" required="required" placeholder="Email del usuario" className="form-control"
                            readOnly="readOnly"
                            defaultValue={editing.email}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="email">Email del usuario</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating">
                        {showPass ? <EyeOutlined onClick={()=>setShowPass(false)} /> : <EyeInvisibleOutlined onClick={()=>setShowPass(true)} /> }
                        <input type={showPass ? 'text' : 'password' } name="password"  placeholder="Escriba su password" className="form-control"
                            onChange={handleInputChange} 
                        />
                        <label htmlFor="password">Contraseña { validationError.password ? <span className="tip-warning">* {validationError.password[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-6 col-lg-4">

                    <button type="submit" className="btn btnRegistrarse" disabled={registeringUser ? true : ''}>
                        { registeringUser ? <LoadingOutlined /> : <SafetyOutlined /> } Guardar cambios
                    </button>

                </div>
            </div>
        </form>
        {success ? 
            <div className="alert alert-info text-center">
                {success}
            </div>
        :null}
        </>
    );
}
 
export default EditUser;
