import React from 'react';

import { FileExcelOutlined } from '@ant-design/icons';

import { downloadCSV } from '../Helpers/important-helpers';

const FilterComponent = ({ filterText, onFilter, onClear, filteredUsers, userLoggedIn }) => {

    const Export = ({ onExport }) => 
    <button className="btnExport" style={{ marginRight: "5px" }} onClick={e => onExport(e.target.value)}>
        <FileExcelOutlined /> exportar
    </button>
    return ( 
        <>
        <div className="input-group">

            {filteredUsers.length > 0 && userLoggedIn.role < 4 ?
                <Export onExport={() => downloadCSV(filteredUsers)} />
            :null}

            <input type="text" id="search" className="form-control inputSearch" aria-label="Buscar..." aria-describedby="group_search"
                placeholder="buscar..."
                value={filterText}
                onChange={onFilter}
            />
            {filterText !== '' ? 
                <button className="input-group-text" id="group_search" onClick={onClear}>X</button>
            :null}
        </div>
        </>
     );
}
 
export default FilterComponent;