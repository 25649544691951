import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Select from 'react-select';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import  { SafetyOutlined, LoadingOutlined } from '@ant-design/icons';
import { formatToCurrency } from "../Helpers/important-helpers";

import BenefitsAndCover from './BenefitsAndCover';

const AddOrder = ({countries, userLoggedIn, products}) => {
    const [ placingOrder, setPlacingOrder ] = useState(false);
    const [ validationError, setValidationError ] = useState({});
    const [ orderComplete, setOrderComplete ] = useState(false);
    const [ productDetail, setProductDetail ] = useState(null);
    
    const [ value, setValue] = useState();
    

    const [ orderHeader, setOrderHeader ] = useState({
        user_id: userLoggedIn.id,
        agency_id: userLoggedIn.counter_of.id,
        wholesaler_id: userLoggedIn.counter_of.wholesaler_id,
        quantity: 1,
        request_ivisa: 0
    });


    const [ theCountry, setTheCountry ] = useState({
        residence_country   : "",
        country_origin      : "",
        country_destination : ""
    });


    useEffect( ()=>{
        
        setOrderHeader({
            ...orderHeader,
            phone: value
        });

    }, [value]);

    async function handleSubmit(e){
        e.preventDefault();

        try {
            
            setPlacingOrder(true);

            const { data:passenger } = await Axios.post(`${process.env.REACT_APP_API_URL}/api/passenger`, orderHeader);
            setValidationError(passenger.message);

            if(passenger.code === 200 || passenger.code === 201){

                
                if ( passenger.passenger.id ){

                    const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/api/orders`, {...orderHeader, pending_days: passenger.passenger.pending_days, passenger_id: passenger.passenger.id});


                    if( (passenger.code === 200 || passenger.code === 201) && data.code === 201 ){
                        
                        setOrderComplete(data);
                        e.target.reset();

                    }else{
                        setValidationError(data.message);
                    }
                }
            }
            
            setPlacingOrder(false);

        } catch (error) {
            console.log( error );
            setPlacingOrder(false);
        }


    }


    function handleInputChange(e){

        setOrderHeader({
            ...orderHeader,
            [e.target.name]: e.target.value
        });

        setValidationError({});
    }



    const handleInputChangeSelect = (value, field) => {
        
        setTheCountry({
            ...theCountry,
            [field]: value.id
        });

        setOrderHeader({
            ...orderHeader,
            ...theCountry,
            [field]: value.id
        });

        setValidationError({});
    };


    // console.log(orderHeader);


    return ( 
        <>
        {orderComplete ? <ThankYou /> 
        
        :

            <form onSubmit={handleSubmit}>
            <strong>Datos del pasajero</strong><br/><br/>
            <div className="row">
                <div className="col-lg-3">
                    <div className="form-floating">
                        <input type="text"  name="fname" required="required" placeholder="Nombre" className="form-control"
                            onChange={handleInputChange}
                            // value={orderData.fname}
                        />
                        <label htmlFor="fname">Nombre { validationError && validationError.fname ? <span className="tip-warning">* {validationError.fname[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">
                        <input type="text"  name="lname" required="required" placeholder="Apellidos" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="lname">Apellidos { validationError && validationError.lname ? <span className="tip-warning">* {validationError.lname[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">
                        <input type="email"  name="email" required="required" placeholder="Email" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="email">Email { validationError && validationError.email ? <span className="tip-warning">* {validationError.email[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">
                        <input type="email"  name="email_confirmation" required="required" placeholder="Confirmar email" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="email">Confirmar email { validationError && validationError.email_confirmation ? <span className="tip-warning">* {validationError.email_confirmation[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">
                        <PhoneInput
                            name="phone"
                            international
                            defaultCountry="PE"
                            value={value}
                            onChange={setValue}
                        />

                        {/* <input type="tel"  name="phone" required="required" placeholder="Teléfono" className="form-control"
                            onChange={handleInputChange}
                        /> */}
                        {/* <label htmlFor="phone">Teléfono { validationError && validationError.phone ? <span className="tip-warning">* {validationError.phone[0]}</span> : null }</label> */}
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">
                        <Select className="SelectReact" 
                            options={countries}
                            getOptionLabel = {(option) => option.country}
                            getOptionValue = {(option) => option.id}
                            aria-label="País de residencia"
                            placeholder="País de residencia"
                            value={theCountry.value}
                            onChange={(value) => handleInputChangeSelect(value, 'residence_country') }
                        />
                        <label className="lblErrorSelect">{ validationError && validationError.residence_country ? <span className="tip-warning">* {validationError.residence_country[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">
                        <input type="date"  name="birthday" required="required" placeholder="Fecha de nacimiento" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="birthday">Fecha de nacimiento { validationError && validationError.birthday ? <span className="tip-warning">* {validationError.birthday[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">
                        <Select className="SelectReact" 
                            options={countries}
                        
                            getOptionLabel = {(option) => option.country}
                            getOptionValue = {(option) => option.id}
                            aria-label="País del pasaporte"
                            placeholder="País del pasaporte"
                            value={theCountry.value}
                            onChange={(value) => handleInputChangeSelect(value, 'passport_country') }
                        />
                        <label className="lblErrorSelect">{validationError && validationError.passport_country ? <span className="tip-warning">* {validationError.passport_country[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">
                        <input type="text"  name="passport" required="required" placeholder="ID Pasaporte" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="passport">ID Pasaporte { validationError && validationError.passport ? <span className="tip-warning">* {validationError.passport[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-floating">

                        <select className="SelectReact form-control" name="request_ivisa"
                            value={orderHeader.request_ivisa}
                            onChange={ handleInputChange }
                        >
                            <option value="0">No</option>
                            <option value="1">Si</option>
                        </select>
                        <label htmlFor="return_date">¿Desea solicitar el servicio de iVisa?</label>
                    </div>
                </div>
            </div>

            <br/>
            <strong>Seleccione el producto { validationError && validationError.product_id ? <span className="tip-warning">* {validationError.product_id[0]}</span> : null }</strong><br/><br/>
            <div className="row g-2 productToOrder">
                {products.map( (prod) => (
                    <div className="col-lg-3 col-6"
                        key={prod.id}
                    >
                        <div className={ prod.id === orderHeader.product_id ? 'inner active' : 'inner'}
                            onClick={() => setOrderHeader({
                                ...orderHeader,
                                product_id: prod.id,
                                total_price: prod.price,
                                quantity: 1,
                                days: prod.days
                            })}
                        >
                            <h3>{prod.product_name}</h3>
                            <strong>{prod.currency}$ {prod.id === 1 ? prod.price * orderHeader.quantity : formatToCurrency(prod.price) }</strong> <hr/>
                            <small>{ prod.id === 1 ? orderHeader.quantity : prod.days} días</small>
                        </div>
                        <p className="btn ctaDetalleOrden"
                            onClick={ () => setProductDetail(prod)}
                        >VER DETALLE</p>

                        {productDetail ? 
                        <div className="wrapProductDetail">
                            <div className="rightPanel">
                                <a href="#!"
                                    className="closeProductDetal"
                                    onClick={ () => setProductDetail(null)}
                                >Cerrar</a>

                                <div className="innerProduct">
                                    {/* <h1>{productDetail.product_name}</h1>
                                    <div contentEditable='false' dangerouslySetInnerHTML={{ __html: productDetail.description }}></div> */}

                                    {productDetail.id < 3 ? 
                                        <BenefitsAndCover 
                                            tipoPoliza='internacional'  
                                        />
                                    : 
                                        <BenefitsAndCover 
                                            tipoPoliza='ambos' 
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        :null}

                    </div>
                ))}

                {orderHeader.product_id === 1 ? 
                <div className="col-lg-3">
                    <div className="form-floating">
                        <select className="SelectReact form-control" name="quantity"
                            value={orderHeader.quantity}
                            onChange={ handleInputChange }
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select>
                        <label htmlFor="quantity">Cantidad de días { validationError && validationError.quantity ? <span className="tip-warning">* {validationError.quantity[0]}</span> : null }</label>
                    </div>  
                </div>
                :null}
            </div>


            <br/>
            <strong>Datos del vuelo</strong><br/><br/>

            <div className="row">
                <div className="col-lg-4">
                    <div className="form-floating">
                        <input type="text" name="airline" required="required" placeholder="Aerolínea" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="airline">Aerolínea { validationError && validationError.airline ? <span className="tip-warning">* {validationError.airline[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-floating">
                        <input type="text" name="flight_number" required="required" placeholder="Número de vuelo" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="flight_number">Número de vuelo { validationError && validationError.flight_number ? <span className="tip-warning">* {validationError.flight_number[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-floating">
                        <input type="date" name="departure_date" required="required" placeholder="Fecha de salida" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="departure_date">Fecha de salida { validationError && validationError.departure_date ? <span className="tip-warning">* {validationError.departure_date[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-floating">
                        <input type="date" name="return_date" required="required" placeholder="Fecha de regreso" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="return_date">Fecha de regreso { validationError && validationError.return_date ? <span className="tip-warning">* {validationError.return_date[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-floating">
                        <Select className="SelectReact" 
                            options={countries}
                            getOptionLabel = {(option) => option.country}
                            getOptionValue = {(option) => option.id}
                            aria-label="País de origen"
                            placeholder="País de origen"
                            value={theCountry.value}
                            onChange={(value) => handleInputChangeSelect(value, 'country_origin') }
                        />
                        { validationError && validationError.country_origin ? <span className="tip-warning">* {validationError.country_origin[0]}</span> : null }
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-floating">
                        <Select  className="SelectReact" 
                        
                            options={countries}
                            getOptionLabel = {(option) => option.country}
                            getOptionValue = {(option) => option.id}
                            aria-label="País de destino"
                            placeholder="País de destino"
                            value={theCountry.value}
                            onChange={(value) => handleInputChangeSelect(value, 'country_destination') }
                        />
                        { validationError && validationError.country_destination ? <span className="tip-warning">* {validationError.country_destination[0]}</span> : null }
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-floating">
                        <textarea name="remark" placeholder="Nota adicional" className="form-control notaAdicional"
                            onChange={handleInputChange}></textarea>
                        <label htmlFor="remark" className="lblNota">Nota adicional</label>
                    </div> 
                </div>
            </div>


            <div className="row">
                <div className="col-lg-4">
                    <button type="submit" className="btn btnRegistrarse" disabled={placingOrder ? true : ''}>
                        { placingOrder ? <LoadingOutlined /> : <SafetyOutlined /> }  Solicitar activación
                    </button>
                </div>
                {validationError && validationError.length > 0 ?
                    <div className="col-lg-8">
                        <div className="text-center alert alert-danger">
                            {validationError}
                        </div>
                    </div>
                :null}
            </div>
            </form>

        }
        
        </>
    );
}
 
export default AddOrder;



const ThankYou = () => {

    return(
        <div className="thankYou">

            <p>Su orden ha sido completada satisfactoriamente.</p>

        </div>
    );
}