import React, { useState, useEffect } from 'react';
import Axios from "axios";
import Swal from 'sweetalert2';
// import { dateToYMD } from "../Helpers/important-helpers";
import DataTable from 'react-data-table-component';
import { CloseOutlined, FileAddOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';

//Components
import AddAgencyToMyUser from '../Components/AddAgencyToMyUser';
import AddCounter from '../Components/AddCounter';
import FilterComponent from '../Components/FilterComponent';


const Agencies = ({ countries, userLoggedIn }) => {
    const [ agencyList, setAgencyList ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ creating, setCreating ] = useState(false);
    const [ viewAgency, setViewAgency ] = useState([]);
    const [ refreshList, setRefreshList ] = useState(Math.random);

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');
    const filteredAgency = agencyList.filter(
        item => JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
	);
    
    // send only column I want to export
    let dataToExport = filteredAgency.map( function(item){
        return {
            ID: item.id,
            NombreComercial: item.business_name,
            RazónSocial: item.tradename,
            IDTributario: item.tax_id,
            Descripción: item.description,
            Nombre: item.fname,
            Apellido: item.lname,
            Teléfono: item.phone,
            Email: item.main_user && item.main_user.email,
            Fecha: item.created_at
        };
    });

    const subHeaderComponentMemo = React.useMemo(() => {

		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		}; 

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} filteredUsers={dataToExport} userLoggedIn={userLoggedIn} />
		);

	}, [filterText, resetPaginationToggle, dataToExport, userLoggedIn]);


    // columns of table
    const columns = [
        {
            name: 'Tipo',
            selector: row => row.main_office === null ? 'Principal' : 'Sucursal',
            sortable: true
        },
        {
            name: 'Mayorista',
            selector: row => row.wholesaler && row.wholesaler.business_name + " | " + row.wholesaler && row.wholesaler.tradename,
            sortable: true
        },
        {
            name: 'Nombre comercial',
            selector: row => row.business_name,
            sortable: true
        },
        {
            name: 'Razón social',
            selector: row => row.tradename,
            sortable: true
        },
        {
            name: 'ID Tributario',
            selector: row => row.tax_id,
            sortable: true
        },
        {
            name: 'Teléfono',
            selector: row => row.phone,
            sortable: true
        },
        {
            name: 'Nombre',
            selector: row => row.fname,
            sortable: true
        },
        {
            name: 'Apellido',
            selector: row => row.lname,
            sortable: true
        },
        {
            name: 'Descripción',
            selector: row => row.description,
        },
        {
            right: true,
            id: 'actions',
            name: '',
            grow: 2,
            cell: row =>
                userLoggedIn.role < 4 ? (
                    <>
                        <button
                            className="ctaActions"
                            title="VER AGENCIA"
                            onClick={ () => setViewAgency(row)}
                            style={{ marginRight: "5px" }}
                        ><EyeOutlined /></button>
                            
                        {/* <button
                            className="ctaActions"
                            title="EDITAR AGENCIA"
                            // onClick={() => setEditing(row)}
                            style={{ marginRight: "5px" }}
                        ><EditOutlined /></button> */}

                        <button 
                            className="ctaActions ctaDelete"
                            title="ELIMINAR AGENCIA"
                            onClick={ () => Swal.fire({
                                            title: '¿Deseas eliminar esta agencia?',
                                            'icon' : 'warning',
                                            'showCancelButton': 'true',
                                            'cancelButtonText' : 'NO',
                                            'confirmButtonText' : 'SI'
                                        }).then( (result) => {
                                            if( result.isConfirmed){
                                                deleteAgency(row.id)
                                            }
                                        })
                                    }
                            ><DeleteOutlined /></button>
                    </>
                ) : null
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Filas por páginas',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    }

    useEffect( () => {
        let unmonted = false;

        async function getAgency(){

            try{
                setLoading(true);
                
                const { data: agency } = await Axios.get(`${process.env.REACT_APP_API_URL}/api/agency`);
                setAgencyList( agency.data );
                setLoading(false);

            }catch(error){
            
                console.log(error);
                setLoading(false);
            }
        }

        if( !unmonted ){
            getAgency();
        }

        return () => { unmonted = true }

    }, [viewAgency, refreshList]);





    async function deleteAgency(usrr){

        try{
            
            const { data:agency } = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/agency/${usrr}`);
            setRefreshList(Math.random);

            if( agency.code === 200){
                Swal.fire('¡Eliminada!',
                          '¡La agencia ha sido eliminada satisfactoriamente!',
                          'success');
            }else{
                Swal.fire('¡Error al intentar!',
                        'Debe eliminarse todos los counters antes de continuar.',
                        'error');
            }

        }catch(error){
            console.log(error);
        }
    }



    async function deleteUser(usrr){

        try{
            
            const { data:user } = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${usrr}`);
            setRefreshList(Math.random);

            // console.log(user);

            if( user.code === 200){
                Swal.fire('¡Eliminado!',
                          '¡El usuario ha sido eliminado satisfactoriamente!',
                          'success');
            }else{
                Swal.fire('¡Error al intentar!',
                        'No se pudo eliminar el usuario porque tiene orden o agencia a su nombre.',
                        'error');
            }

        }catch(error){
            console.log(error);
        }

    }    


    return(
        <div className="contentDashboard">

            {creating ?
                <aside className="rightPanel">
                    <div className="inner">
                        <button className="btnClose"
                            onClick={ () => setCreating(false) }
                        ><CloseOutlined /> Cerrar</button>

                        <h2>Nueva sucursal</h2>

                        <div className="clearfix"><hr/></div>

                        <AddAgencyToMyUser 
                            countries={countries}
                            userLoggedIn={userLoggedIn}
                            mainoffice={userLoggedIn.agencies[0].id}
                        />

                    </div>
                </aside>
            :null}

            { viewAgency.id > 0 ?
                <aside className="rightPanel">
                    <div className="inner">
                        <button className="btnClose"
                            onClick={ () => setViewAgency([]) }
                        ><CloseOutlined /> Cerrar</button>

                        <h2>{viewAgency.business_name} | {viewAgency.tradename}</h2>

                        <div className="clearfix"><hr/><br/></div>

                        
                        <fieldset>
                            <legend>Counters</legend>
                            <div className="clearfix"></div>
                            
                            <table className="table tableCounter">
                                <thead>
                                    <tr>
                                        <th>ID / Nombre</th>
                                        <th>Email</th>
                                        <th>Usuario</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { viewAgency.counters.length > 0 ? 

                                        viewAgency.counters.map( cntr => (
                                            <tr key={cntr.id}>
                                                <td>{cntr.counterdata.id} / {cntr.counterdata.fname} {cntr.counterdata.lname}</td>
                                                <td>{cntr.counterdata.email}</td>
                                                <td>{cntr.counterdata.username}</td>
                                                <td>
                                                    <button 
                                                        className="ctaActions ctaDelete"
                                                        title="ELIMINAR COUNTER"
                                                        onClick={ () => Swal.fire({
                                                                        title: '¿Deseas eliminar este counter?',
                                                                        'icon' : 'warning',
                                                                        'showCancelButton': 'true',
                                                                        'cancelButtonText' : 'NO',
                                                                        'confirmButtonText' : 'SI'
                                                                    }).then( (result) => {
                                                                        if( result.isConfirmed){
                                                                            deleteUser(cntr.counterdata.id)
                                                                        }
                                                                    })
                                                                }
                                                    ><DeleteOutlined /></button>
                                                </td>
                                            </tr>
                                        ))

                                    : <tr><td colSpan="4">No tiene counter aún.</td></tr> }
                                    
                                </tbody>
                            </table>

                            {userLoggedIn.role === 3 ? 
                            <>
                                <br/><hr/><br/>
                                <strong>Agregar nuevo counter</strong><br/><br/>
                                <div className="addingUsers">
                                    <AddCounter 
                                        countries={countries}
                                        userLoggedIn={userLoggedIn}
                                        agencyid={viewAgency.id}
                                        mainoffice={userLoggedIn.agencies.length > 0 ? userLoggedIn.agencies[0].id : null}
                                    />
                                </div>
                            </>
                            :null}

                        </fieldset>


                    </div>
                </aside>
            :null}

            
            {userLoggedIn.role === 3  ? 
            <button className="float-start btnAdd"
                onClick={ () => setCreating(true) }
            ><FileAddOutlined /> Crear sucursal</button>
            :null}
            

            {loading ? 'cargando...' : null }

            <DataTable className="table-responsive"
                columns={columns}
                data={filteredAgency}
                title="Agencias Minoristas"
                pagination
                paginationComponentOptions={paginationOptions}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                noDataComponent="No se encontraron resultados"
            />
            
        </div>
    );
}


export default Agencies;