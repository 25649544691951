import React, { useState, useEffect } from 'react';
import Axios from "axios";

import { formatDate, formatToCurrency } from "../Helpers/important-helpers";

const Passenger = ({ passenger_id }) => {
    const [ loading, setLoading ] = useState(false);
    const [ passengerData, setPassengerData ] = useState({});

    useEffect( () => {
        let unmonted = false;

        async function getPassengerData(){

            try{
                setLoading(true);
                
                const { data: passenger } = await Axios.get(`${process.env.REACT_APP_API_URL}/api/passenger/${passenger_id}`);
                setPassengerData( passenger );

                setLoading(false);

            }catch(error){
            
                console.log(error);
                setLoading(false);
            }
        }

        if( !unmonted ){
            getPassengerData();
        }

        return () => { unmonted = true }
        

    }, [passenger_id]);


    // console.log(passengerData);


    return ( 
        <>
            {loading ? 'cargando pasajero...' : null }
            
            <table className="table">
                <tbody>
                    <tr>
                        <td>
                            <strong>Pasaporte #</strong> <br/>
                            {passengerData.passport}
                        </td>
                        <td>
                            <strong>Teléfono</strong> <br/>
                            {passengerData.phone}
                        </td>
                        <td>
                            <strong>Email</strong> <br/>
                            {passengerData.email}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>País pasaporte</strong> <br/>
                            {passengerData.passport_country && passengerData.passport_country.country}
                        </td>
                        <td>
                            <strong>Fecha de Nacimiento</strong> <br/>
                            {formatDate(passengerData.birthday)}
                            {/* {passengerData.birthday} */}
                        </td>
                        <td>
                            <strong>País de residencia</strong> <br/>
                            {passengerData.residence_country && passengerData.residence_country.country}
                        </td>
                    </tr>
                </tbody>
            </table>
            <br/>

            {passengerData.orders && passengerData.orders.map( (ordr) =>(
                <div key={ordr.id}>
                    <h3 className={'d-flex justify-content-between ' + ordr.order_status}>
                        <span>Orden# {ordr.id}</span>
                        <span>{ordr.order_status}</span>
                        {ordr.policy_pdf !== null ? 
                            <span>Póliza: <a href={`${process.env.REACT_APP_STATIC_URL}/${ordr.policy_pdf}`} rel="noreferrer" target="_blank">Descargar PDF</a></span>
                        :null}
                    </h3>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Aerolínea / Vuelo</strong> <br/>
                                    {ordr.airline} / {ordr.flight_number}
                                </td>
                                <td>
                                    <strong>País y fecha de salida</strong> <br/>
                                    {ordr.country_origin && ordr.country_origin.country} / {formatDate(ordr.departure_date)}
                                </td>
                                <td>
                                    <strong>País destino y fecha de regreso</strong> <br/>
                                    {ordr.country_destination && ordr.country_destination.country} - {formatDate(ordr.return_date)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Producto</strong> <br/>
                                    {ordr.product.product_name}
                                    {ordr.product.id === 1 ? ' / ' + ordr.quantity + ' días' : null}
                                </td>
                                <td>
                                    <strong>Monto total</strong> <br/>
                                    {ordr.product.currency} ${formatToCurrency(ordr.total_price)}
                                </td>
                                <td>
                                    <strong>Fecha de la orden: </strong> <br/>
                                    {formatDate(ordr.created_at)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Agencia</strong> <br/>
                                    {ordr.agency.business_name}
                                </td>
                                <td colSpan="2">
                                    <strong>Counter ID / Nombre</strong> <br/>
                                    {ordr.user.id+' / '+ordr.user.fname+' '+ordr.user.lname}
                                </td>
                                <td>
                                    
                                </td>
                            </tr>
                            {ordr.remark !== null ?
                            <tr className="alert alert-info">
                                <td colSpan="4">
                                    <strong>Notas</strong><br/>
                                    {ordr.remark}
                                </td>
                            </tr>
                            :null}
                        </tbody>
                    </table>
                    <br/>
                </div>
            ))}

        </>
    );
}
 
export default Passenger;