import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from "axios";

import { MailOutlined, SyncOutlined, SafetyOutlined, SendOutlined, WarningOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import logoQues from '../assets/images/QUESTRAVEL-LOGO.svg';

const Forgot = ({ location }) => {
    const [ error, setError ] = useState('');
    const [ recovering, setRecovering ] = useState(false);
    const [ userFound, setUserFound ] = useState(null);
	const [ emailToRecover, setEmailToRecover ] = useState({ email: "" });

	function handleInputChange(e) {
		setError('');
        setUserFound(null);
		setEmailToRecover({
			...emailToRecover,
			[e.target.name]: e.target.value
		});
	}

	async function handleSubmit(e) {
		e.preventDefault();

        try {
            setRecovering(true);
            const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/api/users/forgot`, emailToRecover);
            setEmailToRecover({ email: "" });

            if( data.code === 200 ){
                setUserFound(data.message);
            }

            if( data.code === 422 ){
                setError(data.message);
            }

            if( data.code === 404 ){
                setError(data.message);
            }

            if( data.code === 400 ){
                setError(data.message);
            }
            
            setRecovering(false);

        } catch (error) {
            console.log(error);
            setRecovering(false);
        }

	}



    if( location.hash ){

        return (
            <ChangePassword 
                hashtoken={location.hash}
            />
        )
    }



    return ( 
        <section className="wrapLogin wrapForgot">
            <div className="container">

                <header>
                    <img src={logoQues} alt="Ques.travel" className="mainLogoQues img-fluid" />
                </header>

                <form onSubmit={handleSubmit}>
                    <div className="form-floating">
                        <MailOutlined />
                        <input type="email" name="email" required="required" placeholder="Escriba su email" className="form-control"
                            onChange={handleInputChange}
                            value={emailToRecover.email}
                        />
                        <label htmlFor="email">Escriba su email</label>
                    </div>

                    <button type="submit" className="btn btnRegistrarse" disabled={recovering ? true : ''}>
                        { recovering ? <SyncOutlined spin /> : <SafetyOutlined /> } Recuperar contraseña
                    </button>

                    <div className="row">
                        <div className="col-lg-6">
                            <p className="hasAccount">
                                Tienes cuenta, <Link to="./login">Ingresa</Link>
                            </p>
                        </div>
                    </div>

                    {recovering ? 
                        <SyncOutlined spin />
                    :null}

                    {userFound ?
                    <div className="alert alert-info">
                        <SendOutlined />
                        <p>{userFound}</p>
                    </div>
                    :null}

                    {error ?
                    <div className="alert alert-danger">
                        <WarningOutlined />
                        <p>{error}</p>
                    </div>
                    :null}
                </form>
                
            </div>
        </section>
    );
}

export default Forgot;





export const ChangePassword = ({hashtoken}) => {
    const [ error, setError ] = useState('');
    const [ showPass, setShowPass ] = useState(false);
    const [ changingPass, setChangingPass ] = useState(false);
    const [ validationError, setValidationError] = useState(null);
    const [ passwordChanged, setPasswordChanged] = useState(null);
    const [ userToken, setUserToken ] = useState({
        'token': hashtoken.substring(1),
        'password': ''
    });

    let history = useHistory();

    function handleInputChange(e) {
        setValidationError(null);
		setUserToken({
			...userToken,
			[e.target.name]: e.target.value
		});        
	}


    async function handleSubmitChange(e) {
		e.preventDefault();

        try {
            setChangingPass(true);
            const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/api/users/changepass`, userToken);

            if( data.code === 200 ){
                setPasswordChanged(data.message);

                setTimeout( () => {
                    history.push("./");
                }, 2000);
            }

            if( data.code === 422 ){
                setValidationError(data.message.password);
            }

            if( data.code === 404 ){
                setError(data.message);

                setTimeout( () => {
                    history.push("./");
                }, 2000);
            }

            if( data.code === 400 ){
                setError(data.message);
            }
            
            setChangingPass(false);

        } catch (error) {
            console.log(error);
            setChangingPass(false);
        }
	}


    return (
        <section className="wrapLogin wrapForgot">
            <div className="container">
                <header>
                    <img src={logoQues} alt="Ques.travel" className="mainLogoQues img-fluid" />
                </header>        
                <form onSubmit={handleSubmitChange}>
                    <div className="form-floating">
                        {showPass ? <EyeOutlined onClick={()=>setShowPass(false)} /> : <EyeInvisibleOutlined onClick={()=>setShowPass(true)} /> }
                        <input type={showPass ? 'text' : 'password' } name="password" required="required" placeholder="Nueva contraseña" className="form-control"
                            onChange={handleInputChange} 
                        />
                        <label htmlFor="password">Nueva contraseña</label>
                    </div>
                    <button type="submit" className="btn btnRegistrarse" disabled={changingPass ? true : ''}>
                        { changingPass ? <SyncOutlined spin /> : <SafetyOutlined /> } Cambiar contraseña
                    </button>

                    <div className="row">
                        <div className="col-lg-6">
                            <p className="hasAccount">
                                Tienes cuenta, <Link to="./login">Ingresa</Link>
                            </p>
                        </div>
                    </div>

                    {changingPass ? 
                        <SyncOutlined spin />
                    :null}

                    {passwordChanged ?
                    <div className="alert alert-info">
                        <SendOutlined />
                        <p>{passwordChanged}</p>
                    </div>
                    :null}

                    {error ?
                    <div className="alert alert-danger">
                        <WarningOutlined />
                        <p>{error}</p>
                    </div>
                    :null}

                    {validationError ?
                    <div className="alert alert-warning">
                        <WarningOutlined />
                        <p>{validationError}</p>
                    </div>
                    :null}
                </form>

            </div>
        </section>        
    );
}