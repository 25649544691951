import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { AppstoreAddOutlined, TeamOutlined, AppstoreOutlined, ApartmentOutlined } from '@ant-design/icons';

import logoQues from '../assets/images/QUESTRAVEL-LOGO.svg';

const Navbar = ({ userLoggedIn, logout }) => {
    
    const location = useLocation();
    
    return (
        <nav className="mainNavbar">
            <div className="container-fluid p-0">

                <Link className="brandFixed" to="/">
                    <img src={logoQues} alt="Ques.travel" className="img-fluid mainLogoQues" />
                </Link>

                <div className="wrapOpciones">
                    <ul className="navbarAdmin">
                        <li className={(location.pathname === '/') ? 'nav-item active' : 'nav-item' }>
                            <Link to="/" className="nav-link"><AppstoreAddOutlined /> Órdenes</Link>
                        </li>

                        { userLoggedIn.role < 3 ? 
                            <li className={location.pathname === '/account/users' ? 'nav-item active' : 'nav-item' }>
                                <Link to="/account/users" className="nav-link"><TeamOutlined /> Usuarios</Link>
                            </li>
                        :null}

                        { userLoggedIn.role === 1 ?
                            <li className={(location.pathname === '/account/wholesalers') ? 'nav-item active' : 'nav-item' }>
                                <Link to="/account/wholesalers" className="nav-link"><ApartmentOutlined /> Mayoristas</Link>
                            </li>
                        :null}

                        { userLoggedIn.role < 4 ?
                            <>
                            <li className={(location.pathname === '/account/agencies') ? 'nav-item active' : 'nav-item' }>
                                <Link to="/account/agencies" className="nav-link"><AppstoreOutlined /> Agencias</Link>
                            </li>
                            </>
                        :null}

                    </ul>
                </div>


            </div>
        </nav>
    )
}
 
export default Navbar;