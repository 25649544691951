import React, { useState, useEffect } from 'react';
import Axios from "axios";
import Swal from 'sweetalert2';

import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component';

//Components
import FilterComponent from '../Components/FilterComponent';

const Wholesalers = ({ countries, userLoggedIn }) => {
    const [ wholesalerList, setWholesalerList ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ viewWholesaler, setViewWholesaler ] = useState([]);
    const [ refreshList, setRefreshList ] = useState(Math.random);

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');
    const filteredWholesaler = wholesalerList.filter(
        item => JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
	);
    
    // send only column I want to export
    let dataToExport = filteredWholesaler.map( function(item){
        return {
            ID: item.id,
            NombreComercial: item.business_name,
            RazónSocial: item.tradename,
            IDTributario: item.tax_id,
            Descripción: item.description,
            Nombre: item.fname,
            Apellido: item.lname,
            Teléfono: item.phone,
            Email: item.main_user && item.main_user.email,
            Fecha: item.created_at
        };
    });

    const subHeaderComponentMemo = React.useMemo(() => {

		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		}; 

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} filteredUsers={dataToExport} userLoggedIn={userLoggedIn} />
		);

	}, [filterText, resetPaginationToggle, dataToExport, userLoggedIn]);



    // columns of table
    const columns = [
        {
            name: 'Nombre comercial',
            selector: row => row.business_name,
            sortable: true
        },
        {
            name: 'Razón social',
            selector: row => row.tradename,
            sortable: true
        },
        {
            name: 'ID Tributario',
            selector: row => row.tax_id,
            sortable: true
        },
        {
            name: 'Teléfono',
            selector: row => row.phone,
        },
        {
            name: 'Nombre',
            selector: row => row.fname,
            sortable: true
        },
        {
            name: 'Apellido',
            selector: row => row.lname,
            sortable: true
        },
        {
            name: 'Descripción',
            selector: row => row.description,
        },
        {
            right: true,
            id: 'actions',
            name: '',
            cell: row =>
                userLoggedIn.role === 1 ? (
                    <>
                        {/* <button
                            className="ctaActions"
                            title="EDITAR AGENCIA"
                            // onClick={() => setEditing(row)}
                            style={{ marginRight: "5px" }}
                        ><EditOutlined /></button> */}
                        
                        <button 
                        className="ctaActions ctaDelete"
                        title="ELIMINAR AGENCIA"
                        onClick={ () => Swal.fire({
                                        title: '¿Deseas eliminar esta agencia?',
                                        'icon' : 'warning',
                                        'showCancelButton': 'true',
                                        'cancelButtonText' : 'NO',
                                        'confirmButtonText' : 'SI'
                                    }).then( (result) => {
                                        if( result.isConfirmed){
                                            deleteWholesaler(row.id)
                                        }
                                    })
                                }
                    ><DeleteOutlined /></button>
                    </>
                ) : null
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Filas por páginas',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    }



    useEffect( () => {
        let unmonted = false;

        async function getWholesaler(){

            try{
                setLoading(true);
                
                const { data: agency } = await Axios.get(`${process.env.REACT_APP_API_URL}/api/wholesaler`);
                setWholesalerList( agency.data );

                setLoading(false);

            }catch(error){
            
                console.log(error);
                setLoading(false);
            }
        }

        if( !unmonted ){
            getWholesaler();
        }

        return () => { unmonted = true }
        

    }, [viewWholesaler, refreshList]);

    


    async function deleteWholesaler(usrr){

        try{
            
            const { data:wholesaler } = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/wholesaler/${usrr}`);
            setRefreshList(Math.random);

            if( wholesaler.code === 200){
                Swal.fire('¡Eliminada!',
                          '¡La agencia ha sido eliminada satisfactoriamente!',
                          'success');
            }

        }catch(error){
            console.log(error);
        }

    }



    return(
        <div className="contentDashboard">

            { viewWholesaler.id > 0 ?
                <aside className="rightPanel">
                    <div className="inner">
                        <button className="btnClose"
                            onClick={ () => setViewWholesaler([]) }
                        ><CloseOutlined /> Cerrar</button>

                        <h2>{viewWholesaler.business_name}</h2>

                        <div className="clearfix"><hr/></div>

                        <fieldset>
                            <legend>Mayorista</legend>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td width="120"><strong>Business name:</strong></td>
                                        <td>{viewWholesaler.business_name}</td>
                                        <td width="40"><strong>Tradename:</strong></td>
                                        <td>{viewWholesaler.tradename}</td>
                                    </tr>
                                    <tr>
                                        <td width="40"><strong>Tax ID:</strong></td>
                                        <td>{viewWholesaler.tax_id}</td>
                                        <td width="120"><strong>Contacto:</strong></td>
                                        <td>{viewWholesaler.fname} {viewWholesaler.lname}</td>
                                    </tr>
                                    <tr>
                                        <td width="120"><strong>Teléfono:</strong></td>
                                        <td>{viewWholesaler.phone}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset> 


                    </div>
                </aside>
            :null}

            
            {loading ? 'cargando...' : null }

            <DataTable className="table-responsive"
                columns={columns}
                data={filteredWholesaler}
                title="Agencias Mayoristas"
                pagination
                paginationComponentOptions={paginationOptions}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                noDataComponent="No se encontraron resultados"
            />
            
            
        </div>
    );
}

export default Wholesalers;