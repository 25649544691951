import React from 'react';
// import { CalculatorOutlined, UserOutlined } from '@ant-design/icons';


const Profile = ( {userLoggedIn} ) => {
    

    // console.log(userLoggedIn);
    

    return(
        <div className="contentDashboard">
            
            <fieldset>
                <legend>Mi perfil</legend>
                <table className="table">
                    <tbody>
                        <tr>
                            <td><strong>Contacto:</strong> {userLoggedIn.fname} {userLoggedIn.lname}</td>
                            <td><strong>E-mail:</strong> {userLoggedIn.email}</td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>

            {userLoggedIn.role === 2 ?
            <fieldset>
                <legend>Agencia</legend>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="120"><strong>Razón social:</strong></td>
                            <td>{userLoggedIn.wholesaler[0].business_name}</td>
                            <td width="150"><strong>Nombre comercial:</strong></td>
                            <td>{userLoggedIn.wholesaler[0].tradename}</td>
                        </tr>
                        <tr>
                            <td width="120"><strong>ID Tributario:</strong></td>
                            <td>{userLoggedIn.wholesaler[0].tax_id}</td>
                            <td width="120"><strong>Teléfono:</strong></td>
                            <td>{userLoggedIn.wholesaler[0].phone}</td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
            :null}

            {userLoggedIn.role === 3 ?
            <>
            <fieldset>
                <legend>Agencia</legend>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="120"><strong>Razón social:</strong></td>
                            <td>{userLoggedIn.agencies[0].business_name}</td>
                            <td width="150"><strong>Nombre comercial:</strong></td>
                            <td>{userLoggedIn.agencies[0].tradename}</td>
                        </tr>
                        <tr>
                            <td width="120"><strong>ID Tributario:</strong></td>
                            <td>{userLoggedIn.agencies[0].tax_id}</td>
                            <td><strong>Contacto:</strong></td>
                            <td>{userLoggedIn.agencies[0].fname} {userLoggedIn.agencies[0].lname}</td>
                        </tr>
                        <tr>
                            <td width="120"><strong>Teléfono:</strong></td>
                            <td>{userLoggedIn.agencies[0].phone}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>

            <fieldset>
                <legend>Mayorista</legend>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="120"><strong>Razón social:</strong></td>
                            <td>{userLoggedIn.createdby.wholesaler[0].business_name}</td>
                            <td width="150"><strong>Nombre comercial:</strong></td>
                            <td>{userLoggedIn.createdby.wholesaler[0].tradename}</td>
                        </tr>
                        <tr>
                            <td width="120"><strong>ID Tributario:</strong></td>
                            <td>{userLoggedIn.createdby.wholesaler[0].tax_id}</td>
                            <td><strong>Contacto:</strong></td>
                            <td>{userLoggedIn.createdby.wholesaler[0].fname} {userLoggedIn.createdby.wholesaler[0].lname}</td>
                        </tr>
                        <tr>
                            <td width="120"><strong>Teléfono:</strong></td>
                            <td>{userLoggedIn.createdby.wholesaler[0].phone}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
            </>
            :null}
        </div>
    );
}

export default Profile;