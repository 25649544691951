import React, {useState } from 'react';
import Axios from 'axios';

// import Select from 'react-select';

import  { SafetyOutlined, LoadingOutlined } from '@ant-design/icons';



const AddWholesalerToMyUser = ({ userLoggedIn, userList }) => {
    
    const [ registeringUser, setRegisteringUser ] = useState(false);
    const [ validationError, setValidationError ] = useState({});
    const [ success, setSuccess] = useState('');

    const [agencyData, setAgencyData] = useState({
		fname: "",
        lname: "",
        phone: "",
        email: "",
        business_name : "",
        tradename: "",
        tax_id: ""
	});

    function handleInputChange(e) {
		setAgencyData({
			...agencyData,
			[e.target.name]: e.target.value
		});

        setValidationError({});
	}


    async function handleSubmit(e) {
		e.preventDefault();

        try {

            setRegisteringUser(true);

            const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/api/wholesaler`, agencyData);

            if( data.code === 422 ){
                
                setRegisteringUser(false);
                setValidationError(data.message);

            }else if( data.code === 201){
                
                setSuccess('¡La agencia ha sido registrada satisfactoriamente!');
                e.target.reset();

                setTimeout( function(){
                    window.location = '/';
                },2000);

            }
            
            setRegisteringUser(false);

        } catch (error) {
            console.log(error);
            setRegisteringUser(false);
        }

	}

    



    return ( 
        <section className="wrapForm">
            <div className="container">
            <h2>Agregando Agencia Mayorista</h2>
            <p>Antes que todo debes crear los datos de tu agencia</p>

            <form onSubmit={handleSubmit}>
                <strong>Datos de la empresa</strong>
                <div className="row">
                    <div className="col-6">
                        <div className="form-floating">
                            <input type="text" name="business_name" required="required" placeholder="Razón social" className="form-control"
                                onChange={handleInputChange}
                            />
                            <label htmlFor="business_name">Razón social { validationError.business_name ? <span className="tip-warning">* {validationError.business_name[0]}</span> : null }</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating">
                            <input type="text" name="tradename" required="required" placeholder="Nombre comercial" className="form-control"
                                onChange={handleInputChange}
                            />
                            <label htmlFor="tradename">Nombre comercial { validationError.tradename ? <span className="tip-warning">* {validationError.tradename[0]}</span> : null }</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating">
                            <input type="text" name="tax_id" required="required" placeholder="ID Tributario" className="form-control"
                                onChange={handleInputChange}
                            />
                            <label htmlFor="tax_id">ID Tributario { validationError.tax_id ? <span className="tip-warning">* {validationError.tax_id[0]}</span> : null }</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating">
                            <input type="text" name="description" placeholder="Descripción" className="form-control"
                                onChange={handleInputChange}
                            />
                            <label htmlFor="description">Descripción</label>
                        </div>
                    </div>
                </div>

                <strong>Contacto</strong>
                <div className="row">
                    <div className="col-6 col-lg-4">
                        <div className="form-floating">
                            <input type="text" name="fname" required="required" placeholder="Nombre" className="form-control"
                                onChange={handleInputChange}
                            />
                            <label htmlFor="fname">Nombre { validationError.fname ? <span className="tip-warning">* {validationError.fname[0]}</span> : null }</label>
                        </div>
                    </div>
                    <div className="col-6 col-lg-4">
                        <div className="form-floating">
                            <input type="text" name="lname" required="required" placeholder="Apellido" className="form-control"
                                onChange={handleInputChange}
                            />
                            <label htmlFor="lname">Apellido { validationError.lname ? <span className="tip-warning">* {validationError.lname[0]}</span> : null }</label>
                        </div>
                    </div>
                    <div className="col-6 col-lg-4">
                        <div className="form-floating">
                            <input type="tel" name="phone" required="required" placeholder="Escriba su teléfono" className="form-control"
                                onChange={handleInputChange}
                            />
                            <label htmlFor="email">Escriba su teléfono { validationError.phone ? <span className="tip-warning">* {validationError.phone[0]}</span> : null }</label>
                        </div>
                    </div>
                    
                    <div className="col-6 col-lg-4">
                        <button type="submit" className="btn btnSubmit btnRegistrarse" disabled={registeringUser ? true : ''}>
                            { registeringUser ? <LoadingOutlined /> : <SafetyOutlined /> } Guardar agencia
                        </button>
                    </div>
                </div>
            </form>
            {success ? 
                <div className="alert alert-info text-center">
                    {success}
                </div>
            :null}
            </div>
        </section>
    );
}
 
export default AddWholesalerToMyUser;
