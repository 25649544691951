import React, { useState, useEffect } from 'react';
import Axios from "axios";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { formatDate } from "../Helpers/important-helpers";
import DataTable from 'react-data-table-component';

import { CloseOutlined, UserAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

//Components
import AddUser from '../Components/AddUser';
import EditUser from '../Components/EditUser';
import FilterComponent from '../Components/FilterComponent';


const Users = ( { userLoggedIn, countries, setRefresh } ) => {
    const [ loading, setLoading ] = useState(false);
    const [ creating, setCreating ] = useState(false);
    const [ editing, setEditing ] = useState('');
    const [ refreshList, setRefreshList ] = useState(Math.random);
    const [ userList, setUserList ] = useState([]);

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');
    const filteredUsers = userList.filter(
        // item => item.lname && item.lname.toLowerCase().includes( filterText.toLowerCase() ),
        item => JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
	);    


    let roles = ['ADMIN', 'MAYORISTA', 'AGENCIA', 'COUNTER'];

    // send only column I want to export
    let dataToExport = filteredUsers.map( function(item){
        return {
            ID: item.id,
            Tipo: roles[item.role - 1],
            Nombre: item.fname,
            Apellido: item.lname,
            Email: item.email,
            Creado_por: item.createdby && item.createdby.fname + ' ' +item.createdby.lname,
            Fecha: formatDate(item.created_at)
        };
    });


    const subHeaderComponentMemo = React.useMemo(() => {

		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} filteredUsers={dataToExport} userLoggedIn={userLoggedIn} />
		);

	}, [filterText, resetPaginationToggle, dataToExport, userLoggedIn]);
    


    const hst = useHistory()

    useEffect( () => {

        async function getUsers(){

            try{
                setLoading(true);
                
                const { data: users } = await Axios.get(`${process.env.REACT_APP_API_URL}/api/users`);
                setUserList( users.data );

                // console.log(users.data);
                setLoading(false);

            }catch(error){
                console.log(error);
            }
        }

        
        if( userLoggedIn.role < 3 ) {
            getUsers();
        }
        

    }, [userLoggedIn, creating, refreshList]);



    if(userLoggedIn.role === 3){
        hst.push('/');
    }



    async function deleteUser(usrr){

        try{
            
            const { data:user } = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${usrr}`);
            setRefreshList(Math.random);

            // console.log(user);

            if( user.code === 200){
                Swal.fire('¡Eliminado!',
                          '¡El usuario ha sido eliminado satisfactoriamente!',
                          'success');
            }else{
                Swal.fire('¡Error al intentar!',
                        'No se pudo eliminar el usuario porque tiene orden o agencia a su nombre.',
                        'error');
            }

        }catch(error){
            console.log(error);
        }

    }




    // columns of table
    const columns = [
        // {
        //     id: 'id',
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: true
        // },
        {
            id: 'role',
            name: 'Tipo',
            selector: (row) => roles[row.role - 1],
            sortable: false
        },
        {
            id: 'fname',
            name: 'Nombre',
            selector: row => row.fname,
            sortable: true
        },
        {
            id: 'lname',
            name: 'Apellido',
            selector: row => row.lname,
            sortable: true
        },
        {
            id: 'email',
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            grow: 2
        },
        {
            right: true,
            id: 'actions',
            name: '',
            cell: row =>
            <>
                { userLoggedIn.role < 3 && userLoggedIn.id !== row.id ? (
                    <button
                        className="ctaActions"
                        title="EDITAR USUARIO"
                        onClick={() => setEditing(row)}
                        style={{ marginRight: "5px" }}
                    ><EditOutlined /></button>
                ) : null }

                { (userLoggedIn.role === 1 || userLoggedIn.role === 2) && userLoggedIn.id !== row.id ? (
                    <button 
                        className="ctaActions ctaDelete"
                        title="ELIMINAR USUARIO"
                        onClick={ () => Swal.fire({
                                        title: '¿Deseas eliminar este usuario?',
                                        'icon' : 'warning',
                                        'showCancelButton': 'true',
                                        'cancelButtonText' : 'NO',
                                        'confirmButtonText' : 'SI'
                                    }).then( (result) => {
                                        if( result.isConfirmed){
                                            deleteUser(row.id)
                                        }
                                    })
                                }
                    ><DeleteOutlined /></button>
                ) : null }
            </>
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Filas por páginas',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    }


    return(
        <div className="contentDashboard">

            {creating ? 
            <aside className="rightPanel addingUsers d-flex align-items-center">
                <div className="inner">
                    <button className="btnClose"
                        onClick={ () => setCreating(false)}
                    ><CloseOutlined /> Cerrar</button>

                    <h2>Nuevo usuario</h2>

                    <div className="clearfix"><hr/></div>

                    <AddUser 
                        countries={countries}
                        userLoggedIn={userLoggedIn}
                        userList={userList}
                    />

                </div>
            </aside>
            :null}

            {editing ? 
            <aside className="rightPanel addingUsers d-flex align-items-center">
                <div className="inner">
                    <button className="btnClose"
                        onClick={ () => setEditing(false)}
                    ><CloseOutlined /> Cerrar</button>

                    <h2>Editando usuario</h2>

                    <div className="clearfix"><hr/></div>
                    <br/>

                    <EditUser 
                        userLoggedIn={userLoggedIn}
                        editing={editing}
                        setRefresh={setRefresh}
                    />

                </div>
            </aside>
            :null}

            {userLoggedIn.role < 4 ? 
                <button className="float-start btnAdd"
                    onClick={ () => setCreating(true) }
                ><UserAddOutlined /> Crear usuario</button>
            :null}
            

            {loading ? 'cargando...' : null }

            <DataTable className="table-responsive"
                columns={columns}
                data={filteredUsers}
                title="Listado de usuarios"
                pagination
                paginationComponentOptions={paginationOptions}
                fixedHeader
                fixedHeaderScrollHeight="550px"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                noDataComponent="No se encontraron resultados"
            />
        </div>
    );
}

export default Users;