import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import Users from './Users';
import Orders from './Orders';
import Profile from './Profile';
import Agencies from './Agencies';
import Wholesalers from './Wholesalers';

import AddWholesalerToMyUser from '../Components/AddWholesalerToMyUser';
import AddAgencyToMyUser from '../Components/AddAgencyToMyUser';


const Account = ( {countries, userLoggedIn, products, setRefresh} ) => {

    const hasNotWholesaler = (userLoggedIn && userLoggedIn.wholesaler.length === 0) && userLoggedIn.role === 2 ? <AddWholesalerToMyUser userLoggedIn={userLoggedIn} /> : null;
    const hasNotAgency     = userLoggedIn.agencies.length === 0 && userLoggedIn.role === 3 ? <AddAgencyToMyUser userLoggedIn={userLoggedIn} /> : null;

    return ( 

        <>
            <div className="wrapContentAccount">
                <div className="container-fluid">

                    <Switch>
                        { userLoggedIn.role === 2 ? hasNotWholesaler : hasNotAgency }

                        { userLoggedIn.role < 3 ? 
                            <Route path="/account/users" 
                                render={(props) => <Users 
                                    {...props}
                                    countries={countries}
                                    userLoggedIn={userLoggedIn}
                                    setRefresh={setRefresh}
                                />} 
                            />
                        :null}
                        
                        { userLoggedIn.role === 1 ?
                            <Route path="/account/wholesalers" 
                                render={(props) => <Wholesalers 
                                    {...props}
                                    countries={countries}
                                    userLoggedIn={userLoggedIn}
                                />} 
                            />
                        :null}

                        
                        { userLoggedIn.role < 4 ?
                        <Route path="/account/agencies" 
                            render={(props) => <Agencies 
                                {...props}
                                countries={countries}
                                userLoggedIn={userLoggedIn}
                            />} 
                        />
                        :null}


                        <Route path="/account/profile" 
                            render={(props) => <Profile 
                                {...props}
                                countries={countries}
                                userLoggedIn={userLoggedIn}
                            />} 
                        />

                        <Route path="/" 
                            render={(props) => <Orders 
                                {...props}
                                countries={countries}
                                userLoggedIn={userLoggedIn}
                                products={products}
                            />} 
                        />
                        
                    </Switch>

                </div>
            </div>
        </>
    );
}


export default Account;