import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { LogoutOutlined } from '@ant-design/icons';

import user_icon from '../assets/images/user.png';

const IdentificationNav = ({ userLoggedIn, logout }) => {


    return ( 
        <>
            <ul className="d-flex flex-row-reverse bd-highlight nav navbarIdentification">
                <>
                <li className="nav-item logoutWrapLink">
                    <Link to="/" className="nav-link"
                        onClick={ () => Swal.fire({
                            title: '¿Estás seguro que deseas salir?',
                            'icon' : 'warning',
                            'showCancelButton': 'true',
                            'cancelButtonText' : 'NO',
                            'confirmButtonText' : 'SI'
                        }).then( (result) => {
                            if( result.isConfirmed){
                                logout()
                            }
                        })
                        
                        }
                    ><LogoutOutlined /> Logout</Link>
                </li>
                <li className="nav-item userLoggedIn">
                    <Link to="/account/profile" className="nav-link">
                        <img src={user_icon} alt="user" className="rounded-circle user_icon" />
                        {userLoggedIn.fname}
                        <small className="profile_role">{userLoggedIn.roles.role}</small>
                    </Link>
                </li>
                </>
            </ul>
        </>
    );
}
 
export default IdentificationNav;