import React from 'react';

import DataNacional from '../nacional.json';
import DataInternacional from '../internacional.json';

import lloyds from '../assets/images/lloyds.png';

const BenefitsAndCover = ({ tipoPoliza }) => {

    const tipoP = tipoPoliza === 'internacional' ? DataInternacional : DataNacional;

    return ( 
        <>
        <img src={lloyds} alt="Lloyds" class="img-fluid d-block mb-3" width={150} />

        {tipoPoliza === 'ambos' ? 
            <>
                <strong>PÓLIZA DE VIAJES NACIONAL</strong>
                <table className="table table-striped mb-4 tableBeneficio">
                    <thead>
                        <tr>
                            <td></td>
                            <td valign="middle">BENEFICIOS</td>
                            <td>Cobertura geográfica país de residencia</td>
                            <td valign='middle' align='center'>Notas</td>
                        </tr>
                    </thead>

                    <tbody>
                        {DataNacional.map( (row) => (
                            <tr key={row.id}>
                                <td>{row.id}</td>
                                <td>{row.beneficios}</td>
                                <td align="center" valign="middle">{row.incluye}</td>
                                <td>{row.notas}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                
                <br/>
                <strong>PÓLIZA DE VIAJES INTERNACIONAL</strong>
                <table className="table table-striped tableBeneficio">
                    <thead>
                        <tr>
                            <td></td>
                            <td valign="middle">BENEFICIOS</td>
                            <td>Cobertura geográfica mundial excepto país de residencia</td>
                            <td valign='middle' align='center'>Notas</td>
                        </tr>
                    </thead>

                    <tbody>
                        {DataInternacional.map( (row) => (
                            <tr key={row.id}>
                                <td>{row.id}</td>
                                <td>{row.beneficios}</td>
                                <td align="center" valign="middle">{row.incluye}</td>
                                <td>{row.notas}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        :
            <>
            <strong>PÓLIZA DE VIAJES INTERNACIONAL</strong>
            <table className="table table-striped  tableBeneficio">
                <thead>
                    <tr>
                        <td></td>
                        <td valign="middle">BENEFICIOS</td>
                        {tipoPoliza === 'internacional' ?
                            <td>Cobertura geográfica mundial excepto país de residencia</td>
                        :
                            <td>Cobertura geográfica país de residencia</td>
                        }
                        <td valign='middle' align='center'>Notas</td>
                    </tr>
                </thead>

                <tbody>
                    {tipoP.map( (row) => (
                        <tr key={row.id}>
                            <td>{row.id}</td>
                            <td>{row.beneficios}</td>
                            <td align="center" valign="middle">{row.incluye}</td>
                            <td>{row.notas}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </>
        }
        </>
    );
}
 
export default BenefitsAndCover;