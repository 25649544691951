const Footer = () => {

    const dateYear = new Date().getFullYear();

    return ( 
        <footer className="footer">
            <p className="copy">&copy; {dateYear} Questravel Services SRL. Todos los derechos reservados.</p>
        </footer>
    );
}
 
export default Footer;