import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Axios from "axios";

import { deleteToken, getToken, initAxiosInterceptors, setToken } from "./Helpers/auth-helpers";
import { LoadingOutlined } from '@ant-design/icons';

// Styles
import "animate.css/animate.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../src/App.scss';

// Components
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import IdentificationNav from './Components/IdentificationNav';

// Pages
import Login from './Views/Login';
import Forgot from './Views/Forgot';

//Secured pages
import Account from './Views/Account';


initAxiosInterceptors();


function App() {
	const [userLoggedIn, setUserLoggedIn] = useState(null);
	const [isLoadingUserData, setIsLoadingUserData] = useState(true);
	const [countries, setCountries] = useState([]);
	const [ products, setProducts ] = useState([]);
	const [ refresh, setRefresh ] = useState('');

	useEffect(() => {
		let unmonted = false;
        
        async function allCountries(){
            
            try {
                const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}/api/country`);
                setCountries( data );
                
            } catch (error) {
                console.log(error);
            }

        }

		if( !unmonted ){
        	allCountries();
		}

		return () => { unmonted = true};

    }, []);



	useEffect( () => {

		async function loadUser(){

			if( !getToken() ){
				setIsLoadingUserData(false);
				return;
			}

			try{
				// debugger;
				const { data: user } = await Axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`);
				setUserLoggedIn(user);
				setIsLoadingUserData(false);

			} catch( error ){
				setIsLoadingUserData(true);
				console.log(error);
			}

		}

        loadUser();

	}, [refresh]);


	useEffect(() => {
		let unmonted = false;
        
        async function getProducts(){
            const {data:prod} = await Axios.get(`${process.env.REACT_APP_API_URL}/api/products`);
            setProducts(prod);
        }

        getProducts();

		if( !unmonted ){
        	getProducts();
		}

		return () => { unmonted = true};

    }, []);


	async function login(email, password) {
		const { data: user } = await Axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { email, password });
		setUserLoggedIn(user.user);
		setToken(user.token);
	}

	function logout(){
		setUserLoggedIn(null);
		deleteToken();
	}


	if( isLoadingUserData ){
		return (
			<div className="FullLoadingPage">
				<LoadingOutlined />
			</div>
		);
	}

return (
    <Router>
	
		{userLoggedIn ? 

			<div className="wrapMain">
				<aside className="mainLeftContent">
					<div className="innerSidebar">
						<Navbar 
							userLoggedIn={userLoggedIn}
							logout={logout}
						/>
					</div>
				</aside>
				<div className="mainRightContent">
					<IdentificationNav 
						userLoggedIn={userLoggedIn}
						logout={logout}
					/>

					<SecuredRoutes
						userLoggedIn={userLoggedIn}
						countries={countries}
						products={products}
						setRefresh={setRefresh}
					/>

					<Footer />
				</div>
			</div>

			:
			
			<UnsecuredRoutes 
				login={login}
			/>
		}

    </Router>
  );
}


export default App;



function SecuredRoutes({userLoggedIn, countries, products, setRefresh}){

	return(
		<Switch>

			<Route path="/" 
				render={(props) => <Account 
				{...props} 
				countries={countries}
				userLoggedIn={userLoggedIn} 
				setRefresh={setRefresh}
				products={products} />} 
				default
			/>

		</Switch>
	);
}


function UnsecuredRoutes({ login, setIsLoadingUserData }){

	return(
		<Switch>

			<Route path="/forgot" 
				render={(props) => <Forgot 
					{...props}
				/>} 
			/>

			<Route path="/" 
				render={(props) => <Login 
					{...props}
					login={login} 
					setIsLoadingUserData={setIsLoadingUserData}
				/>} 
			/>

		</Switch>
	);
}