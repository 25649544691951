import React, { useState, useEffect } from 'react';
import Axios from "axios";

import { formatDate, formatToCurrency } from "../Helpers/important-helpers";
import DataTable from 'react-data-table-component';
import { AppstoreAddOutlined, CloseOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';

//Components
import AddOrder from '../Components/AddOrder';
import AddOrderPolicy from '../Components/AddOrderPolicy';
import FilterComponent from '../Components/FilterComponent';
import Passenger from '../Components/Passenger';


const Orders = ( {countries, userLoggedIn, products} ) => {
    const [ orderList, setOrderList ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ creating, setCreating ] = useState(false);
    const [ viewOrder, setViewOrder ] = useState({});

    const [ addingPolicy, setAddingPolicy ] = useState({});


    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');
    const filteredOrder = orderList.filter(
        item => JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
	);
    
    // send only column I want to export
    let dataToExport = filteredOrder.map( function(item){
        return {
            Número: item.id,
            Fecha: formatDate(item.created_at),
            Estado: item.order_status,
            'Confirmación Póliza': item.policy_confirmation_code ? item.policy_confirmation_code : 'Pendiente',
            'Precio Total': item.product.currency+' '+item.total_price,
            Producto: item.product.product_name,
            'Counter COD / Nombre': item.user.id+' / '+item.user.fname+' '+item.user.lname,
            'Agencia: Nombre comercial / Razón social / ID Tributario': item.agency.business_name+' / '+item.agency.tradename+' / '+item.agency.tax_id,
            'Mayorista: Nombre comercial / Razón social / ID Tributario': item.wholesaler.business_name+' / '+item.wholesaler.tradename+' / '+item.wholesaler.tax_id,
            'Pasajero / Email / Teléfono': item.passenger.fname+' / '+item.passenger.lname+' / '+item.passenger.email+' / '+item.passenger.phone
        };
    });

    const subHeaderComponentMemo = React.useMemo(() => {

		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		}; 

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} filteredUsers={dataToExport} userLoggedIn={userLoggedIn} />
		);

	}, [filterText, resetPaginationToggle, dataToExport, userLoggedIn]);


    // columns of table
    const columns = [
        {
            name: 'Orden #',
            selector: row => row.id,
            sortable: true
        },
        {
            name: 'Fecha',
            selector: row => formatDate(row.created_at),
            sortable: true
        },
        {
            name: 'Pasajero',
            selector: row => row.passenger.fname +' '+row.passenger.lname,
            sortable: true
        },
        {
            name: 'Producto',
            selector: row => row.product.product_name,
            sortable: true
        },
        {
            name: 'Monto',
            selector: row => 'USD$ ' + formatToCurrency(row.total_price),
            sortable: true
        },
        {
            name: 'Sucursal',
            selector: row => row.agency.business_name,
            sortable: true
        },
        {
            name: 'Counter',
            selector: row => row.user.fname+ ' ' + row.user.lname,
            sortable: true
        },
        {
            name: 'Estado',
            selector: row => row.order_status,
            sortable: true
        },
        {
            name: 'Pólizas',
            // selector: row => row.policy_confirmation_code ? row.policy_confirmation_code : 'Sin póliza',
            selector: row => row.policy_pdf ? <a href={`${process.env.REACT_APP_STATIC_URL}/${row.policy_pdf}`} target="_blank" rel="noreferrer">Descargar PDF</a> : 'Sin póliza',
            sortable: true
        },
        {
            right: true,
            id: 'actions',
            name: '',
            grow: 2,
            cell: row =>
                userLoggedIn.role  === 1 ? (
                    <>
                    <button
                        className="ctaActions"
                        title="VER ORDEN"
                        onClick={ () => setViewOrder(row)}
                        style={{ marginRight: "5px" }}
                    ><EyeOutlined /></button>

                    { !row.policy_confirmation_code ?
                        <button
                            className="ctaActions"
                            title="+ Agregar Póliza"
                            onClick={ () => setAddingPolicy(row) }
                        ><PlusCircleOutlined /></button>
                    :null}
                    </>
                ) : (
                    <>
                    <button
                        className="ctaActions"
                        title="VER ORDEN"
                        onClick={ () => setViewOrder(row)}
                        style={{ marginRight: "5px" }}
                    ><EyeOutlined /></button>  
                    </>
                )
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Filas por páginas',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    }


    // console.log(viewOrder);
    // console.log(addDays(new Date(), +365));

    // const oneYearLater = addDays(new Date(viewOrder.created_at));

    useEffect( () => {
        let unmonted = false;

        async function getOrders(){

            try{
                setLoading(true);
                
                const { data: orders } = await Axios.get(`${process.env.REACT_APP_API_URL}/api/orders`);
                setOrderList( orders.data );

                setLoading(false);

            }catch(error){
            
                console.log(error);
                setLoading(false);
            }
        }

        if( !unmonted ){
            getOrders();
        }

        return () => { unmonted = true }
        

    }, [creating, addingPolicy]);

    

    return(
        <div className="contentDashboard">

            { viewOrder.id > 0 ?
            <aside className="rightPanel">
                <div className="inner">
                    <button className="btnClose"
                        onClick={ () => setViewOrder([]) }
                    ><CloseOutlined /> Cerrar</button>

                    <h2><small>Órdenes del pasajero:</small> <br/>{viewOrder.passenger.fname +' '+viewOrder.passenger.lname}</h2>

                    <div className="clearfix"><hr/></div>

                    <Passenger 
                        passenger_id={viewOrder.passenger_id}
                    />

                </div>
            </aside>
            :null}

            { addingPolicy.id > 0 ?
                <aside className="rightPanel">
                    <div className="inner">
                        <button className="btnClose"
                            onClick={ () => setAddingPolicy({}) }
                        ><CloseOutlined /> Cerrar</button>

                        <h2>Agregando póliza la orden # {addingPolicy.id}</h2>

                        <div className="clearfix"><hr/></div>

                        <AddOrderPolicy
                            countries={countries}
                            orderData={addingPolicy}
                        />
                    </div>
                </aside>
            :null}

            {creating ?

                <div className="wrapCreateOrder">
                    <button className="btnClose"
                        onClick={ () => setCreating(false) }
                    ><CloseOutlined /></button>

                    <div className="inner">

                        <h2>Solicitando activación</h2>

                        <AddOrder 
                            countries={countries}
                            userLoggedIn={userLoggedIn}
                            products={products}
                        />

                    </div>
                </div>

            :<>
                {/* {userLoggedIn.role === 4 && userLoggedIn.counter_of.id > 0 ?  */}
                {userLoggedIn.role > 2 ? 
                    <button className="float-start btnAdd"
                        onClick={ () => setCreating(true) }
                    ><AppstoreAddOutlined /> Crear orden</button>
                :null}
                
                
                {loading ? 'cargando órdenes...' : null }

                <DataTable className="table-responsive"
                    columns={columns}
                    data={filteredOrder}
                    title="Últimas órdenes"
                    pagination
                    paginationComponentOptions={paginationOptions}
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    noDataComponent="No se encontraron resultados"
                    onRowDoubleClicked={ (ord) => setViewOrder(ord)}
                />
            </>
            }


        </div>
    );
}

export default Orders;