import React, {useState } from 'react';
import Axios from 'axios';
// import Select from 'react-select';
import  { SafetyOutlined, LoadingOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';



const AddUser = ({ userLoggedIn, userList }) => {
    
    const [ registeringUser, setRegisteringUser ] = useState(false);
    const [ validationError, setValidationError ] = useState({});
    const [ success, setSuccess] = useState('');
    const [ showPass, setShowPass ] = useState(false);

    const [userData, setUserData] = useState({
		fname: "",
        lname: "",
        email: "",
        email_confirmation:"",
		password: ""
	});

    function handleInputChange(e) {
		setUserData({
			...userData,
			[e.target.name]: e.target.value
		});

        setValidationError({});
        setSuccess('');
	}


    async function handleSubmit(e) {
		e.preventDefault();

        try {

            setRegisteringUser(true);

            const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/api/register`, userData);

            if( data.code === 422 ){
                
                setRegisteringUser(false);
                setValidationError(data.message);

            }else if( data.code === 201){
                
                setSuccess('¡El usuario ha sido registrado satisfactoriamente!');
                e.target.reset();
                // console.log(data);

            }
            
            setRegisteringUser(false);

        } catch (error) {
            console.log(error);
            setRegisteringUser(false);
        }

	}


    return ( 
        <>
        <form onSubmit={handleSubmit}>
            
            <div className="row">
                <div className="col-12">
                    <div className="form-floating">
                        <input type="text" name="fname" required="required" placeholder="Nombre" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="fname">Nombre { validationError.fname ? <span className="tip-warning">* {validationError.fname[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-floating">
                        <input type="text" name="lname" required="required" placeholder="Apellidos" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="lname">Apellidos { validationError.lname ? <span className="tip-warning">* {validationError.lname[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating">
                        <input type="email" name="email" required="required" placeholder="Escriba su email" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="email">Escriba su email { validationError.email ? <span className="tip-warning">* {validationError.email[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating">
                        <input type="email" name="email_confirmation" required="required" placeholder="Confirmar email" className="form-control"
                            onChange={handleInputChange}
                        />
                        <label htmlFor="email">Confirmar email { validationError.email ? <span className="tip-warning">* {validationError.email[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-floating">
                        {showPass ? <EyeOutlined onClick={()=>setShowPass(false)} /> : <EyeInvisibleOutlined onClick={()=>setShowPass(true)} /> }
                        <input type={showPass ? 'text' : 'password' } name="password" required="required" placeholder="Escriba su password" className="form-control"
                            onChange={handleInputChange} 
                        />
                        <label htmlFor="password">Contraseña { validationError.password ? <span className="tip-warning">* {validationError.password[0]}</span> : null }</label>
                    </div>
                </div>
                <div className="col-12 col-lg-6">

                    <button type="submit" className="btn btnRegistrarse" disabled={registeringUser ? true : ''}>
                        { registeringUser ? <LoadingOutlined /> : <SafetyOutlined /> } Registrar usuario
                    </button>

                </div>
            </div>
        </form>
        {success ? 
            <div className="alert alert-info text-center">
                {success}
            </div>
        :null}
        </>
    );
}
 
export default AddUser;
