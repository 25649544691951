import React, {useState } from 'react';

// import { dateToYMD } from "../Helpers/important-helpers";
import { getToken } from "../Helpers/auth-helpers";

import  { SafetyOutlined, LoadingOutlined } from '@ant-design/icons';

const AddOrderPolicy = ({ orderData }) => {
    const [ orderPolicyIssued, setOrderPolicyIssued ] = useState(false);
    const [ orderUpdated, setOrderUpdated ] = useState({});
    const [ placingOrder, setPlacingOrder ] = useState(false);
    const [ validationError, setValidationError ] = useState({});

    const [ selectedFile, setSelectedFile ] = useState(null);
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};
    const token = getToken();

    const [ orderPolicyData, setOrderPolicyData ] = useState({
        passenger_id: orderData.passenger_id,
        order_id: orderData.id
    });


    // Save Experience
    async function handleSubmit(e){
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('policy_pdf', selectedFile);
        formData.append('policy_confirmation_code', orderPolicyData.policy_confirmation_code);
        
        setPlacingOrder(true);

        fetch(
            `${process.env.REACT_APP_API_URL}/api/orders/${orderData.id}`,
                {
                    method: 'POST',
                    body: formData,
                    headers: new Headers({
                        Authorization: `bearer ${token}`,
                    }),
                }
            )
            .then((response) => response.json())
            .then((result) => {

                if( result.code === 200){

                    setSelectedFile(null);
                    e.target.reset();

                    setOrderUpdated(result.order);
                    setOrderPolicyIssued(true);

                    setPlacingOrder(false);

                }else{
                    setValidationError(result.message);
                    // console.log(result);
                }


            })
            .catch((error) => {
                setValidationError(error);
                setPlacingOrder(false);
            });        

    }



    function handleInputChange(e){

        setOrderPolicyData({
            ...orderPolicyData,
            [e.target.name]: e.target.value
        });

        setValidationError({});
    }


    return(
        <>
        {orderPolicyIssued ? 

            <div className="thankYou alert alert-success">

                <strong>La póliza ha sido emitida satisfactoriamente.</strong>

                {orderUpdated && orderUpdated.passenger.pending_days > 0 ?
                    <p>Los días restantes luego del próximo viaje son {orderUpdated && orderUpdated.passenger.pending_days} válidos por los próximos 365 días. </p>
                :
                    <p>La póliza es válida por los próximos 365 días. </p>
                }

            </div>
        
        :<form onSubmit={handleSubmit}>

                <strong>Póliza</strong><br/><br/>

                <div className="row">
                    <div className="col-6">
                        <div className="form-floating">
                            <input type="text" name="policy_confirmation_code" placeholder="Código de confirmación" className="form-control"
                                onChange={handleInputChange}
                            />
                            <label htmlFor="policy_confirmation_code" className="lblNota">Código de confirmación { validationError.policy_confirmation_code ? <span className="tip-warning">* {validationError.policy_confirmation_code[0]}</span> : null }</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating">
                            <input type="file" name="policy_pdf" className="form-control" 
                                onChange={changeHandler}
                            />
                            <label htmlFor="file">Cargar PDF { validationError && validationError.policy_pdf ? <span className="tip-warning">* {validationError.policy_pdf[0]}</span> : null }</label>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-6">
                        <button type="submit" className="btn btnRegistrarse" disabled={placingOrder ? true : ''}>
                            { placingOrder ? <LoadingOutlined /> : <SafetyOutlined /> }  Guardar póliza
                        </button>
                    </div>
                </div>
            </form>
            }
        </>
    );

}

export default AddOrderPolicy;
